import React from 'react';
import Layout from '../../components/Layouts';
import axios from 'axios';
import { Collapse, Tabs } from 'antd';

import VegaChart from "../../components/VegaChart"

import SunburstPlot from './sunburst';

export const frontmatter = {
    title: `Languages Spoken Across the Globe`,
    written: `2019-09-03`,
    updated: `2019-09-07`,
    layoutType: `post`,
    contentType: `dataviz`,
    path: `/world-languages/`,
    category: `VISUALISATION, ARJUN ASKS`,
    image: `./poster.jpg`,
    description: `Visualisation of Top 90 languages spoken around the world`
}

const { Panel } = Collapse;
const { TabPane } = Tabs;

  
const languagesGroupedByBranch = (
    <div className="story-content">                        
    <p>
        The first plot shows the language grouped by their parent branch where I define parent as anything before the hyphen in the branch name,
        for example, the branch names Indo-EuropeanRomance and Indo-EuropeanGermanic get rolled into the parent group "Indo".
    </p>            
    <p style={{marginTop: "5px"}}>
        You can zoom into the plot to see the ones that are spoken in few countries and/or spoken by fewer people.
        You can also click on the bubble to see all the languages associated and double click to reset the selection.
    </p>
    
    <VegaChart id="languages-grouped" chartName="languages-grouped" specUrl="https://gist.githubusercontent.com/katnoria/dafdabd7a515010783f34f6bf5d4795d/raw/8773c0de3f0c85b1b6bc35384d2cd10a94dddcb2/languages_grouped_selector.json">
    </VegaChart>
</div>
);

const topTenMostWidelySpokenLanguages = (
    <div className="story-content">                        
    <p>
        I expected Mandarin, Hindi, English and, Spanish to be in the list but surprised to find Japanese, Punjabi and, Marathi in the top 10 list.
    </p>            
    <VegaChart id="top-10-languages" chartName="top-10-languages" specUrl="https://gist.githubusercontent.com/katnoria/4bfbfa6c5e0c45f0582b791ee947f892/raw/f72a12c6149b041f28ff8632a48ed87664b3bede/top_10_lang.json">
    </VegaChart>
</div>
);

const topTenLanguagesByContinent = (
    <div className="story-content">
    <p>
        This plot shows the 10 most spoken languages with-in the continent.
        The top observations for us were that Mandarin is spoken by nearly half of the population in Asia, 
        Egyptian Arabic is the most widely spoken language in Africa and though Spanish with 33% tops the list in Europe, English is not very far behind at 27%.
    </p>
    <Tabs defaultActiveKey="1001">
    <TabPane tab="Asia" key="1001">
        <VegaChart id="top-10-asia" chartName="top-10-asia" specUrl="https://gist.githubusercontent.com/katnoria/ba2d3cedab15bc3743645ae9ef18fecc/raw/ec10593b5a0a9aaad77669a539cf49d948695499/top_10_asian_lang.json">
        </VegaChart>
    </TabPane>
    <TabPane tab="Africa" key="1002">
        <VegaChart id="top-10-africa" chartName="top-10-africa" specUrl="https://gist.githubusercontent.com/katnoria/0beb360f2581587a1869ad85af35f2f9/raw/12c1e95285ed325161a5013fa615e9924f84863a/top_10_african_lang.json">
        </VegaChart>
    </TabPane>
    <TabPane tab="Europe" key="1003">
        <VegaChart id="top-10-europe" chartName="top-10-europe" specUrl="https://gist.githubusercontent.com/katnoria/4ceeec41606bf091e4e7933699d4242f/raw/782c5996f439ef576c57c9da0d24b5ebf887cf0d/top_10_european_lang.json">
        </VegaChart>
    </TabPane>
    <TabPane tab="Americas" key="1004">
        <p>Sorry, there is no data available for Americas on the Wikipedia page.</p>
    </TabPane>
  </Tabs> 
</div>
);

const PanelHeader = (props) => {
    const { title } = props;
    return (
        <span className="is-size-5">{title}</span>
    )
}

export default class WorldLanguagesPage extends React.Component {
    state = {
        dataset: null
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        axios.get("https://gist.githubusercontent.com/katnoria/9585b6bd24cb9e0eb9be8f3d488e90dc/raw/771a888cc293ae479477b28ee4a7efa70e059a08/sunburst-data.csv")
            .then(response => {
                let dataset = response.data;
                // console.log(dataset);
                this.setState({ dataset });
            });
    }

    render() {
        const { dataset } = this.state;
        return (
            <div className="dataviz-page">
            <Layout data={this.props.data} location={this.props.location}>
                <div style={{ maxWidth: 800, margin: "0px auto" }}>
                    <h1 className="header-title">Languages Spoken Across the Globe</h1>
                    <h4 className="header-subtitle" style={{ marginTop: 20, marginBottom: 40 }}>
                        Desktop version, 03 SEPTEMBER, 2019
              </h4>
                    <div className="story-content">
                        <p>
                            A few days ago Arjun, my eight-year-old, asked about the most widely spoken languages in the world.
                            We summoned Alexa 🤖, who was very upfront about the source of her answer "According to one Amazon customer,
                            Mandarin is the most widely spoken language".
                            Soon after, he wanted to know the second most widely spoken language and so forth.
                            So I did what any Dad with some coding skills would do, I made some plots for him 
                            and secretly hoped that he might well get interested in Python, R or D3/Javascript, but alas, may be next time.
                            However, the process of doing it made me think, maybe I can create a new category called "Arjun Asks" and
                            share the visualisations I create in response to his queries (although I am yet to add the search/filter on tags 😅).
                        </p>
                        <p style={{display: "block", marginTop: 30}}>
                            Now, I do not do this for all his questions but I have done this a few times earlier.
                            For example, there was a time when he wanted to create superhero names for his Lego mini-figures 
                            which somewhat lead to superhero name generator using deep learning (RNN) <a href="/generativetext">here</a>,
                            and more recently he wanted to know whether the Avengers Endgame was the most popular movie ever made, which
                            was explored <a href="/mcuboxoffice">here</a>.
                  </p>
                    </div>
                    <div className="story-content" style={{display: "block", marginTop: 30}}>
                        <p>
                            I used the data available on &nbsp; 
                            <a href="https://en.wikipedia.org/wiki/List_of_languages_by_number_of_native_speakers" target="_blank">Wikipedia</a> for 
                            all the plots on this page.
                            The page shows 90 languages that have at least 10 million first-language speakers as of 2019, 
                            which covers <strong>72.38%</strong> of the world population.
                        </p>
                    </div>
                    <VegaChart 
                        id="continent-breakdown" 
                        chartName="continent-breakdown" 
                        specUrl="https://gist.githubusercontent.com/katnoria/62b657efc26fb9226908fe2d9f0e7bf7/raw/950d2acfd4af4b19743317ef94d943fefe4181b0/languages_continent_pct.json">
                    </VegaChart>                    
                    <Collapse bordered={false} defaultActiveKey={['1']}>
                    <Panel header={
                        <PanelHeader title="What are the Top 10 most widely spoken languages?" />
                    } key="1">
                        {topTenMostWidelySpokenLanguages}
                    </Panel>                        
                    <Panel header={
                        <PanelHeader title="What are the most spoken languages in the world?" />
                    } key="2">
                        {languagesGroupedByBranch}
                    </Panel>                        
                    <Panel header={
                        <PanelHeader title="What are the Top 10 languages in each continent?" />
                    } key="3">
                        {topTenLanguagesByContinent}
                    </Panel>                        
                    </Collapse>
                </div>
                <div className="story-content" style={{display: "block", marginTop: 30}}>
                    <p>
                        We could also see most of the above information in a single concise plot below. 
                        The innermost ring shows the continent, the next shows the countries within each continent 
                        and the outermost shows the languages spoken with in the countries.
                    </p>
                    <p style={{marginTop: 5, color: "gray"}}>
                        Hover over the circle to see the details.
                    </p>
                    {dataset && <SunburstPlot dataset={dataset} />}                    
                    <p style={{marginBottom: 10}}>
                        And that's it folks. Thanks for visiting.
                    </p>
                </div>
            </Layout>
            </div>
        )
    }
}